import * as React from 'react';
import clsx from 'clsx';
import styles from './components/home.module.css';
import Layout from 'components/layout';
import Footer from 'components/layout/footer';
import Booking from 'components/feedback';
import Header from './components/header';
import Tiles from 'components/tile';
import TS from './components/ts';
import ClientReviews from '../components/about-us/client-reviews';
import Recognition from 'components/recognition';
import { meta } from '../metaData';

const Routes = require('../components/routes').Routes;

const hwhbg = [
  {
    name: 'mvp-development',
    title: 'Go-to Market Fast',
    text: 'Minimium Valuable Product (MVP) Development',
    to: Routes.SERVICES_MVP_DEVELOPMENT,
  },
  {
    name: 'product-development',
    title: 'Boost Your Product',
    text: 'Product Development and Re-engineering',
    to: Routes.SERVICES_PRODUCT_DEVELOPMENT,
  },
  {
    name: 'dedicated-team',
    title: 'Strengthen Dev Team',
    text: 'Dedicated Team',
    to: Routes.SERVICES_DEDICATED_TEAM,
  },
  {
    name: 'digital-transformation',
    title: 'Optimize Workflow',
    text: 'Digital Transformation',
    to: Routes.SERVICES_DIGITAL_TRANSFORMATION,
  },
];
function Main() {
  return (
    <Layout metaData={{ main: meta.main }} navBtnTextColor="var(--black-color)">
      <Header />
      <main className={styles.main}>
        <section className="section gray-wave">
          <div className="inner">
            <h2 className="title">How we can help your business grow</h2>
            <p className={styles.subtitle}>
              From ideation to CI/CD to MVP to workflow optimization – to help you create an
              actionable startup anchored by the strengths of Brocoders expertise
            </p>
            <Tiles list={hwhbg} />
          </div>
        </section>
        <TS />
        <ClientReviews />
        <section className="section">
          <div className="inner">
            <h2 className={clsx(styles.title, styles.recognition)}>Recognition</h2>
            <Recognition />
          </div>
        </section>
        <Booking />
        <Footer />
      </main>
    </Layout>
  );
}

export default Main;
