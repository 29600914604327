import React, { memo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
// styles
import clsx from 'clsx';
import styles from './header.module.css';
//components
import Contacts from './contacts';
import broVideo from '../../../video/bro-video.mp4';

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      bgImg: file(relativePath: { eq: "bg-img-header-home.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bgVideoImg: file(relativePath: { eq: "first-frame-video.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <header className={styles.container}>
      <video
        autoPlay
        loop
        playsInline
        className={styles.video}
        muted
        poster={data.bgVideoImg.childImageSharp.fluid.srcWebp}
      >
        <source src={broVideo} type="video/mp4" />
      </video>
      <div className={styles.wave} />
      <Img
        className={styles.background}
        fluid={data.bgImg.childImageSharp.fluid}
        loading="eager"
        fadeIn={false}
        alt=""
      />
      <div className={styles.content}>
        <h1 className={clsx('header-title', styles.title)}>
          On-demand Technical Team for SaaS Products and Midsize Businesses
        </h1>
        <p className={clsx(styles.text, 'header-text')}>
          We&rsquo;re here to accompany you all along the road from an MVP to a successful product
          stage or wherever your business goals and ambitions will take you.
        </p>
        <Contacts containerStyles={styles.contactBtnsBox} />
      </div>
    </header>
  );
};

export default memo(Header);
